body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.hello-world__tech {
  font-weight: 700;
  font-style: italic;
}
.home-page {
    width: 100%;
    height: 100%;
    background-image: -webkit-gradient(linear, left top, right top, from(hsl(0, 80%, 60%)),to(hsl(0, 90%, 80%)));
    background-image: -webkit-linear-gradient(left, hsl(0, 80%, 60%),hsl(0, 90%, 80%));
    background-image: linear-gradient(to right, hsl(0, 80%, 60%),hsl(0, 90%, 80%));
    position: absolute;
}
.home-header {
    width: 100%;
    height: 10%;
    background-image: -webkit-gradient(linear, left top, right top, from(hsl(0, 90%, 80%)), to(hsl(0, 80%, 60%)));
    background-image: -webkit-linear-gradient(left, hsl(0, 90%, 80%), hsl(0, 80%, 60%));
    background-image: linear-gradient(to right, hsl(0, 90%, 80%), hsl(0, 80%, 60%));
    position: absolute;
    top: 0%;
    border-bottom: 5px solid hsl(0, 80%, 60%);
    text-align: center;
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.home-footer {
    width: 100%;
    height: 10%;
    background-image: -webkit-gradient(linear, left top, right top, from(hsl(0, 90%, 80%)), to(hsl(0, 80%, 60%)));
    background-image: -webkit-linear-gradient(left, hsl(0, 90%, 80%), hsl(0, 80%, 60%));
    background-image: linear-gradient(to right, hsl(0, 90%, 80%), hsl(0, 80%, 60%));
    position: absolute;
    top: 90%;
    border-top: 5px solid hsl(0, 80%, 60%);
}
.option-btn-0 {
    cursor: pointer;
    position: absolute;
    width: 10%;
    height: 15%;
    top: 40%;
    left: 20%;
    background-color: hsl(0, 80%, 45%);
    border-radius: 10px;
    border: 8px solid hsl(0, 80%, 85%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;

}
.option-btn-0:hover {
    background-color: hsl(0, 80%, 60%);
    border: 8px solid hsl(0, 80%, 70%);
}
.option-btn-1 {
    cursor: pointer;
    position: absolute;
    width: 10%;
    height: 15%;
    top: 40%;
    left: 31%;
    background-color: hsl(0, 80%, 50%);
    border-radius: 10px;
    border: 8px solid hsl(0, 80%, 80%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;

}
.option-btn-1:hover {
    background-color: hsl(0, 80%, 65%);
    border: 8px solid hsl(0, 80%, 60%);
}
.option-btn-2 {
    cursor: pointer;
    position: absolute;
    width: 10%;
    height: 15%;
    top: 40%;
    left: 42%;
    background-color: hsl(0, 80%, 55%);
    border-radius: 10px;
    border: 8px solid hsl(0, 80%, 75%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;

}
.option-btn-2:hover {
    background-color: hsl(0, 80%, 70%);
    border: 8px solid hsl(0, 80%, 60%);
}
.option-btn-3 {
    cursor: pointer;
    position: absolute;
    width: 10%;
    height: 15%;
    top: 40%;
    left: 53%;
    background-color: hsl(0, 80%, 60%);
    border-radius: 10px;
    border: 8px solid hsl(0, 95%, 65%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;

}
.option-btn-3:hover {
    background-color: hsl(0, 80%, 50%);
    border: 8px solid hsl(0, 80%, 80%);
}
.option-btn-4 {
    cursor: pointer;
    position: absolute;
    width: 10%;
    height: 15%;
    top: 40%;
    left: 64%;
    background-color: hsl(0, 80%, 65%);
    border-radius: 10px;
    border: 8px solid hsl(0, 95%, 60%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;

}
.option-btn-4:hover {
    background-color: hsl(0, 80%, 45%);
    border: 8px solid hsl(0, 95%, 90%);
}
.client-options {
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: -webkit-gradient(linear, left top, right top, from(hsl(0, 80%, 70%)), to(hsl(0, 90%, 90%)));
    background-image: -webkit-linear-gradient(left, hsl(0, 80%, 70%), hsl(0, 90%, 90%));
    background-image: linear-gradient(to right, hsl(0, 80%, 70%), hsl(0, 90%, 90%));
}
.client-option-btn-0 {
    cursor: pointer;
    position: absolute;
    width: 8%;
    height: 15%;
    top: 40%;
    left: 31%;
    background-color: hsl(0, 70%, 65%);
    border-radius: 60px;
    border: 8px solid hsl(0, 90%, 85%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;

}
.client-option-btn-0:hover {
    background-color: hsl(0, 70%, 65%);
    border: 8px solid hsl(0, 90%, 95%);
}
.client-option-btn-1 {
    cursor: pointer;
    position: absolute;
    width: 8%;
    height: 15%;
    top: 20%;
    left: 46%;
    background-color: hsl(0, 90%, 75%);
    border-radius: 60px;
    border: 8px solid hsl(0, 90%, 70%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;

}
.client-option-btn-1:hover {
    background-color: hsl(0, 90%, 65%);
    border: 8px solid hsl(0, 80%, 90%);
}
.client-option-btn-2 {
    cursor: pointer;
    position: absolute;
    width: 8%;
    height: 15%;
    top: 40%;
    left: 61%;
    background-color: hsl(0, 90%, 80%);
    border-radius: 60px;
    border: 8px solid hsl(0, 80%, 65%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;

}
.client-option-btn-2:hover {
    background-color: hsl(0, 90%, 65%);
    border: 8px solid hsl(0, 80%, 85%);
}
.client-option-btn-3 {
    cursor: pointer;
    position: absolute;
    width: 8%;
    height: 15%;
    top: 60%;
    left: 46%;
    background-color: hsl(0, 90%, 75%);
    border-radius: 60px;
    border: 8px solid hsl(0, 90%, 70%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;

}
.client-option-btn-3:hover {
    background-color: hsl(0, 90%, 75%);
    border: 8px solid hsl(0, 90%, 65%);
}
.client-option-btn-4 {
    cursor: pointer;
    position: absolute;
    width: 10%;
    height: 10%;
    top: 80%;
    left: 45%;
    background-color: hsl(0, 90%, 75%);
    border-radius: 60px;
    border: 8px solid hsl(0, 100%, 100%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;

}
.client-option-btn-4:hover {
    background-color: hsl(0, 90%, 70%);
    border: 8px solid hsl(0, 100%, 85%);
}
.client-options-header {
    width: 100%;
    height: 8%;
    background-image: -webkit-gradient(linear, left top, right top, from(hsl(0, 90%, 90%)), to(hsl(0, 80%, 70%)));
    background-image: -webkit-linear-gradient(left, hsl(0, 90%, 90%), hsl(0, 80%, 70%));
    background-image: linear-gradient(to right, hsl(0, 90%, 90%), hsl(0, 80%, 70%));
    position: absolute;
    top:0%;
    border-bottom: 8px solid hsl(0, 80%, 70%);
    text-align: center;
    color: hsl(0, 100%, 100%);
    font-weight: bold;
    padding-top: 1%;
    padding-bottom: 1%;
}
.add-client {
    width: 40%;
    height: 70%;
    position: absolute;
    top: 19.3%;
    left: 29.4%;
    border-radius: 60px;
    border: 8px solid hsl(0, 90%, 75%);
    background-image: -webkit-gradient(linear, right top, left top, from(hsl(0, 90%, 80%)), to(hsl(0, 90%, 85%)));
    background-image: -webkit-linear-gradient(right, hsl(0, 90%, 80%), hsl(0, 90%, 85%));
    background-image: linear-gradient(to left, hsl(0, 90%, 80%), hsl(0, 90%, 85%));
    opacity: 0.9;
}
.add-client-title {
    width: 90%;
    position: absolute;
    top: 15%;
    left: 5%;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-client-fn {
    width: 88%;
    position: absolute;
    top: 21%;
    left: 5%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-client-ln {
    width: 88%;
    position: absolute;
    top: 26.5%;
    left: 5%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}

.add-client-gender {
    width: 90%;
    position: absolute;
    top: 32%;
    left: 5%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-client-race {
    width: 90%;
    position: absolute;
    top: 38%;
    left: 5%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-client-mn {
    width: 88%;
    position: absolute;
    top: 44%;
    left: 5%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-client-ea {
    width: 88%;
    position: absolute;
    top: 50%;
    left: 5%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-client-account-type {
    width: 90%;
    position: absolute;
    top: 56%;
    left: 5%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-client-member-count {
    width: 88%;
    position: absolute;
    top: 62%;
    left: 5%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-client-session-type {
    width: 90%;
    position: absolute;
    top: 68%;
    left: 5%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-client-account-paydate {
    width: 88%;
    position: absolute;
    top: 74%;
    left: 5%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-client-address {
    width: 88%;
    height: 10%;
    position: absolute;
    top: 76%;
    left: 5%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    padding-top: 2%;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-client-btn-cancel {
    cursor: pointer;
    width: 20%;
    position: absolute;
    top: 82%;
    left: 14%;
    border-radius: 60px;
    padding: 10px;
    background-color: hsl(0, 80%, 65%);
    border: 8px solid hsl(0, 100%, 100%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.add-client-btn-cancel:hover {
    background-color: hsl(0, 90%, 70%);
    border: 8px solid hsl(0, 100%, 90%);
}
.add-client-btn-add {
    cursor: pointer;
    width: 20%;
    position: absolute;
    top: 82%;
    left: 66%;
    border-radius: 60px;
    padding: 10px;
    background-color: hsl(0, 80%, 65%);
    border: 8px solid hsl(0, 95%, 75%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.add-client-btn-add:hover {
    background-color: hsl(0, 90%, 75%);
    border: 8px solid hsl(0, 100%, 70%);
}
.add-client-header {
    text-align: center;
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.update-client {
    width: 40%;
    height: 70%;
    position: absolute;
    top: 19.3%;
    left: 29.4%;
    border-radius: 60px;
    border: 8px solid hsl(0, 90%, 75%);
    background-image: -webkit-gradient(linear, right top, left top, from(hsl(0, 90%, 80%)), to(hsl(0, 90%, 85%)));
    background-image: -webkit-linear-gradient(right, hsl(0, 90%, 80%), hsl(0, 90%, 85%));
    background-image: linear-gradient(to left, hsl(0, 90%, 80%), hsl(0, 90%, 85%));
    opacity: 0.9;
}
.update-client-title {
    width: 90%;
    position: absolute;
    top: 27.5%;
    left: 4%;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-client-fn {
    width: 88%;
    position: absolute;
    top: 34%;
    left: 4%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-client-ln {
    width: 88%;
    position: absolute;
    top: 40.5%;
    left: 4%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-client-mn {
    width: 88%;
    position: absolute;
    top: 47%;
    left: 4%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-client-ea {
    width: 88%;
    position: absolute;
    top: 53.5%;
    left: 4%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-client-address {
    width: 88%;
    height: 10%;
    position: absolute;
    top: 60%;
    left: 4%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    padding-top: 2%;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-client-btn-cancel {
    cursor: pointer;
    width: 20%;
    position: absolute;
    top: 78%;
    left: 12%;
    border-radius: 60px;
    padding: 10px;
    background-color: hsl(0, 80%, 65%);
    border: 8px solid hsl(0, 100%, 100%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.update-client-btn-update {
    cursor: pointer;
    width: 20%;
    position: absolute;
    top: 78%;
    left: 66%;
    border-radius: 60px;
    padding: 10px;
    background-color: hsl(0, 80%, 65%);
    border: 8px solid hsl(0, 95%, 75%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.update-client-btn-cancel:hover {
    background-color: hsl(0, 90%, 70%);
    border: 8px solid hsl(0, 100%, 90%);
}
.update-client-btn-update:hover {
    background-color: hsl(0, 90%, 75%);
    border: 8px solid hsl(0, 100%, 70%);
}
.update-client-header {
    text-align: center;
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}

.update-client-search {
    width: 60%;
    position: absolute;
    top: 21%;
    left: 4%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-client-btn-search {
    cursor: pointer;
    width: 22%;
    position: absolute;
    top: 20.5%;
    left: 71%;
    border-radius: 60px;
    padding: 5px;
    border-radius: 60px;
    background-color: hsl(0, 80%, 65%);
    border: 4px solid hsl(0, 95%, 75%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.update-client-btn-search:hover {
    background-color: hsl(0, 90%, 75%);
    border: 4px solid hsl(0, 100%, 70%);
}
.filter-client {
    width: 40%;
    height: 70%;
    top: 19.3%;
    left: 29.4%;
    position: absolute;
    border-radius: 60px;
    border: 8px solid hsl(0, 90%, 75%);
    background-image: -webkit-gradient(linear, right top, left top, from(hsl(0, 90%, 80%)), to(hsl(0, 90%, 85%)));
    background-image: -webkit-linear-gradient(right, hsl(0, 90%, 80%), hsl(0, 90%, 85%));
    background-image: linear-gradient(to left, hsl(0, 90%, 80%), hsl(0, 90%, 85%));
}
.filter-client-filter {
    width: 80%;
    height: 6%;
    position: absolute;
    top: 15%;
    left: 8%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 8px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
    outline: none
}
.filter-client-btn-cancel {
    cursor: pointer;
    width: 20%;
    position: absolute;
    top: 85%;
    left: 41%;
    border-radius: 60px;
    padding: 10px;
    background-color: hsl(0, 80%, 65%);
    border: 8px solid hsl(0, 100%, 100%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.filter-client-btn-cancel:hover {
    background-color: hsl(0, 90%, 70%);
    border: 8px solid hsl(0, 100%, 90%);
}
.filter-client-information {
    width: 80%;
    height: 50%;
    position: absolute;
    top: 28%;
    left: 8.5%;
    border-radius: 5px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 3px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.list-client {
    width: 40%;
    height: 70%;
    top: 19.3%;
    left: 29.4%;
    position: absolute;
    border-radius: 60px;
    border: 8px solid hsl(0, 90%, 75%);
    background-image: -webkit-gradient(linear, right top, left top, from(hsl(0, 90%, 80%)), to(hsl(0, 90%, 85%)));
    background-image: -webkit-linear-gradient(right, hsl(0, 90%, 80%), hsl(0, 90%, 85%));
    background-image: linear-gradient(to left, hsl(0, 90%, 80%), hsl(0, 90%, 85%));
}
.list-client-header {
    text-align: center;
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.list-client-btn-cancel {
    cursor: pointer;
    width: 20%;
    position: absolute;
    top: 85%;
    left: 43%;
    border-radius: 60px;
    padding: 10px;
    background-color: hsl(0, 80%, 65%);
    border: 8px solid hsl(0, 100%, 100%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.list-client-btn-cancel:hover {
    background-color: hsl(0, 90%, 70%);
    border: 8px solid hsl(0, 100%, 90%);
}
.list-client-information {
    width: 70%;
    min-height: 60%;
    max-height: 60%;
    position: absolute;
    top: 16%;
    left: 15%;
    overflow-y: auto;
    border-radius: 10px;
    padding: 10px;
    background-color: hsl(0, 80%, 70%);
}

.client-information {
    height: 50px;
    width: auto;
    cursor: pointer;
    list-style-type: none;
    padding: 5%;
    border-radius: 10px;
    font-weight: bold;
    background-color: hsl(0, 90%, 75%);
}
.client-information:hover {
    background-color: hsl(0, 90%, 70%);
    border: 1px solid hsl(0, 80%, 90%);
}
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: hsl(0, 95%, 70%);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}
.appointment-options {
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: -webkit-gradient(linear, left top, right top, from(hsl(0, 80%, 60%)), to(hsl(0, 100%, 100%)));
    background-image: -webkit-linear-gradient(left, hsl(0, 80%, 60%), hsl(0, 100%, 100%));
    background-image: linear-gradient(to right, hsl(0, 80%, 60%), hsl(0, 100%, 100%));
}
.appointment-option-btn-0 {
    cursor: pointer;
    width: 8%;
    height: 15%;
    position: absolute;
    top: 40%;
    left: 45%;
    border-radius: 60px;
    background-color: hsl(0, 80%, 70%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
    border: 8px solid hsl(0, 80%, 80%);
}
.appointment-option-btn-0:hover {
    background-color: hsl(0, 80%, 50%);
    border: 8px solid hsl(0, 80%, 85%);
}
.appointment-option-btn-1:hover { /* Add */
    background-color: hsl(0, 80%, 50%);
    border: 8px solid hsl(0, 80%, 65%);
}
.appointment-option-btn-2:hover { /* Cancel */
    background-color: hsl(0, 80%, 50%);
    border: 8px solid hsl(0, 80%, 90%);
}
.appointment-option-btn-3:hover { /* Home */
    background-color: hsl(0, 80%, 75%);
    border: 8px solid hsl(0, 80%, 90%);
}
.appointment-option-btn-1 {
    cursor: pointer;
    width: 8%;
    height: 15%;
    position: absolute;
    top: 40%;
    left: 35%;
    border-radius: 60px;
    background-color: hsl(0, 80%, 65%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
    border: 8px solid hsl(0, 80%, 90%);
}
.appointment-option-btn-2 {
    cursor: pointer;
    width: 8%;
    height: 15%;
    position: absolute;
    top: 40%;
    left: 55%;
    border-radius: 60px;
    background-color: hsl(0, 80%, 85%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
    border: 8px solid hsl(0, 80%, 70%);
}
.appointment-option-btn-3 {
    cursor: pointer;
    position: absolute;
    width: 10%;
    height: 10%;
    top: 80%;
    left: 45%;
    background-color: hsl(0, 90%, 75%);
    border-radius: 60px;
    border: 8px solid hsl(0, 100%, 100%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}

.add-appointment {
    width: 40%;
    height: 70%;
    position: absolute;
    top: 20%;
    left: 29.4%;
    opacity: 0.8;
    border-radius: 60px;
    border: 8px solid hsl(0, 90%, 75%);
    background-image: -webkit-gradient(linear, right top, left top, from(hsl(0, 90%, 80%)), to(hsl(0, 90%, 85%)));
    background-image: -webkit-linear-gradient(right, hsl(0, 90%, 80%), hsl(0, 90%, 85%));
    background-image: linear-gradient(to left, hsl(0, 90%, 80%), hsl(0, 90%, 85%));
}
.add-appointment-header {
    text-align: center;
    color: hsl(0, 100%, 100%);
}
.add-appointment-client-id {
    width: 60%;
    left: 20%;
    top: 20%;
    position: absolute;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-appointment-type {
    width: 62%;
    left: 20%;
    top: 30%;
    position: absolute;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-appointment-day {
    width: 62%;
    left: 20%;
    top: 40%;
    position: absolute;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-appointment-time {
    width: 62%;
    left: 20%;
    top: 50%;
    position: absolute;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-appointment-btn-add {
    cursor: pointer;
    width: 20%;
    position: absolute;
    top: 70%;
    left: 60%;
    border-radius: 60px;
    padding: 10px;
    background-color: hsl(0, 80%, 65%);
    border: 8px solid hsl(0, 95%, 75%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;

}.add-appointment-btn-close {
    cursor: pointer;
    width: 20%;
    position: absolute;
    top: 70%;
    left: 20%;
    border-radius: 60px;
    padding: 10px;
    background-color: hsl(0, 80%, 65%);
    border: 8px solid hsl(0, 95%, 75%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.add-appointment-btn-add:hover {
    background-color: hsl(0, 80%, 75%);
    border: 8px solid hsl(0, 80%, 90%);
}

.add-appointment-btn-close:hover {
    background-color: hsl(0, 80%, 75%);
    border: 8px solid hsl(0, 80%, 90%);
}
.update-appointment {
    width: 40%;
    height: 70%;
    position: absolute;
    top: 20%;
    left: 29.4%;
    opacity: 0.8;
    border-radius: 60px;
    border: 8px solid hsl(0, 90%, 75%);
    background-image: -webkit-gradient(linear, right top, left top, from(hsl(0, 90%, 80%)), to(hsl(0, 90%, 85%)));
    background-image: -webkit-linear-gradient(right, hsl(0, 90%, 80%), hsl(0, 90%, 85%));
    background-image: linear-gradient(to left, hsl(0, 90%, 80%), hsl(0, 90%, 85%));
}
.update-appointment-header {
    text-align: center;
    color: hsl(0, 100%, 100%);
}
.update-appointment-client-id {
    width: 60%;
    left: 20%;
    top: 20%;
    position: absolute;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-appointment-type {
    width: 62%;
    left: 20%;
    top: 30%;
    position: absolute;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-appointment-day {
    width: 62%;
    left: 20%;
    top: 40%;
    position: absolute;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-appointment-time {
    width: 62%;
    left: 20%;
    top: 50%;
    position: absolute;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-appointment-btn-update {
    cursor: pointer;
    width: 20%;
    position: absolute;
    top: 70%;
    left: 60%;
    border-radius: 60px;
    padding: 10px;
    background-color: hsl(0, 80%, 65%);
    border: 8px solid hsl(0, 95%, 75%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.update-appointment-btn-update:hover {
    background-color: hsl(0, 80%, 75%);
    border: 8px solid hsl(0, 80%, 90%);
}
.update-appointment-btn-close {
    cursor: pointer;
    width: 20%;
    position: absolute;
    top: 70%;
    left: 20%;
    border-radius: 60px;
    padding: 10px;
    background-color: hsl(0, 80%, 65%);
    border: 8px solid hsl(0, 95%, 75%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.update-appointment-btn-close:hover {
    background-color: hsl(0, 80%, 75%);
    border: 8px solid hsl(0, 80%, 90%);
}
.cancel-appointment {
    width: 40%;
    height: 70%;
    position: absolute;
    top: 20%;
    left: 29.4%;
    opacity: 0.8;
    border-radius: 60px;
    border: 8px solid hsl(0, 90%, 75%);
    background-image: -webkit-gradient(linear, right top, left top, from(hsl(0, 90%, 80%)), to(hsl(0, 90%, 85%)));
    background-image: -webkit-linear-gradient(right, hsl(0, 90%, 80%), hsl(0, 90%, 85%));
    background-image: linear-gradient(to left, hsl(0, 90%, 80%), hsl(0, 90%, 85%));
}
.cancel-appointment-header {
    text-align: center;
    color: hsl(0, 100%, 100%);
}
.cancel-appointment-client-id {
    width: 60%;
    left: 20%;
    top: 20%;
    position: absolute;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.cancel-appointment-type {
    width: 62%;
    left: 20%;
    top: 30%;
    position: absolute;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.cancel-appointment-day {
    width: 62%;
    left: 20%;
    top: 40%;
    position: absolute;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.cancel-appointment-time {
    width: 62%;
    left: 20%;
    top: 50%;
    position: absolute;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.cancel-appointment-btn-cancel {
    cursor: pointer;
    width: 20%;
    position: absolute;
    top: 70%;
    left: 60%;
    border-radius: 60px;
    padding: 10px;
    background-color: hsl(0, 80%, 65%);
    border: 8px solid hsl(0, 95%, 75%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.cancel-appointment-btn-cancel:hover {
    background-color: hsl(0, 80%, 75%);
    border: 8px solid hsl(0, 80%, 90%);
}
.cancel-appointment-btn-close {
    cursor: pointer;
    width: 20%;
    position: absolute;
    top: 70%;
    left: 20%;
    border-radius: 60px;
    padding: 10px;
    background-color: hsl(0, 80%, 65%);
    border: 8px solid hsl(0, 95%, 75%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.cancel-appointment-btn-close:hover {
    background-color: hsl(0, 80%, 75%);
    border: 8px solid hsl(0, 80%, 90%);
}
.profile-options {
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: -webkit-gradient(linear, left top, right top, from(hsl(0, 80%, 55%)), to(hsl(0, 85%, 75%)));
    background-image: -webkit-linear-gradient(left, hsl(0, 80%, 55%), hsl(0, 85%, 75%));
    background-image: linear-gradient(to right, hsl(0, 80%, 55%), hsl(0, 85%, 75%));
}
.profile-option-btn-0 {
    cursor: pointer;
    width: 8%;
    height: 15%;
    position: absolute;
    top: 40%;
    left: 35%;
    border-radius: 60px;
    background-color: hsl(0, 80%, 65%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
    border: 8px solid hsl(0, 80%, 90%);
}
.profile-option-btn-1 {
    cursor: pointer;
    width: 8%;
    height: 15%;
    position: absolute;
    top: 40%;
    left: 45%;
    border-radius: 60px;
    background-color: hsl(0, 80%, 65%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
    border: 8px solid hsl(0, 80%, 90%);
}
.profile-option-btn-2 {
    cursor: pointer;
    width: 8%;
    height: 15%;
    position: absolute;
    top: 40%;
    left: 55%;
    border-radius: 60px;
    background-color: hsl(0, 80%, 65%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
    border: 8px solid hsl(0, 80%, 90%);
}
.profile-option-btn-3 {
    cursor: pointer;
    position: absolute;
    width: 10%;
    height: 10%;
    top: 80%;
    left: 45%;
    background-color: hsl(0, 90%, 75%);
    border-radius: 60px;
    border: 8px solid hsl(0, 100%, 100%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.profile-option-btn-0:hover {
    background-color: hsl(0, 80%, 85%);
    border: 8px solid hsl(0, 80%, 70%);
}
.profile-option-btn-1:hover {
    background-color: hsl(0, 80%, 85%);
    border: 8px solid hsl(0, 80%, 70%);
}
.profile-option-btn-2:hover {
    background-color: hsl(0, 80%, 85%);
    border: 8px solid hsl(0, 80%, 70%);
}
.profile-option-btn-3:hover {
    background-color: hsl(0, 80%, 85%);
    border: 8px solid hsl(0, 80%, 70%);
}
.add-profile {
    width: 40%;
    height: 70%;
    position: absolute;
    top: 20%;
    left: 29.4%;
    opacity: 0.9;
    border-radius: 60px;
    border: 8px solid hsl(0, 90%, 75%);
    background-image: -webkit-gradient(linear, right top, left top, from(hsl(0, 90%, 75%)), to(hsl(0, 90%, 85%)));
    background-image: -webkit-linear-gradient(right, hsl(0, 90%, 75%), hsl(0, 90%, 85%));
    background-image: linear-gradient(to left, hsl(0, 90%, 75%), hsl(0, 90%, 85%));
}
.add-profile-header {
    text-align: center;
    color: hsl(0, 100%, 100%);
}
.add-profile-title {
    width: 62%;
    left: 20%;
    top: 20%;
    position: absolute;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-profile-fn {
    width: 60%;
    left: 20%;
    top: 27%;
    position: absolute;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-profile-ln {
    width: 60%;
    left: 20%;
    top: 34%;
    position: absolute;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-profile-mobile {
    width: 60%;
    left: 20%;
    top: 41%;
    position: absolute;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-profile-email {
    width: 60%;
    left: 20%;
    top: 48%;
    position: absolute;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-profile-club {
    width: 62%;
    left: 20%;
    top: 55%;
    position: absolute;
    border-radius: 30px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-profile-services {
    width: 60%;
    height: 20%;
    left: 20%;
    top: 62%;
    position: absolute;
    border-radius: 30px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-profile-btn-add {
    cursor: pointer;
    width: 20%;
    position: absolute;
    top: 86%;
    left: 62%;
    border-radius: 60px;
    padding: 10px;
    background-color: hsl(0, 80%, 65%);
    border: 8px solid hsl(0, 95%, 75%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.add-profile-btn-add:hover {
    background-color: hsl(0, 80%, 75%);
    border: 8px solid hsl(0, 80%, 90%);
}
.add-profile-btn-close {
    cursor: pointer;
    width: 20%;
    position: absolute;
    top: 86%;
    left: 20%;
    border-radius: 60px;
    padding: 10px;
    background-color: hsl(0, 80%, 65%);
    border: 8px solid hsl(0, 95%, 75%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.add-profile-btn-close:hover {
    background-color: hsl(0, 80%, 75%);
    border: 8px solid hsl(0, 80%, 90%);
}
.update-profile {
    width: 40%;
    height: 70%;
    position: absolute;
    top: 20%;
    left: 29.4%;
    opacity: 0.9;
    border-radius: 60px;
    border: 8px solid hsl(0, 90%, 75%);
    background-image: -webkit-gradient(linear, right top, left top, from(hsl(0, 90%, 75%)), to(hsl(0, 90%, 85%)));
    background-image: -webkit-linear-gradient(right, hsl(0, 90%, 75%), hsl(0, 90%, 85%));
    background-image: linear-gradient(to left, hsl(0, 90%, 75%), hsl(0, 90%, 85%));
}
.update-profile-header {
    text-align: center;
    color: hsl(0, 100%, 100%);
}
.update-profile-title {
    width: 62%;
    left: 20%;
    top: 20%;
    position: absolute;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-profile-fn {
    width: 60%;
    left: 20%;
    top: 27%;
    position: absolute;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-profile-ln {
    width: 60%;
    left: 20%;
    top: 34%;
    position: absolute;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-profile-mobile {
    width: 60%;
    left: 20%;
    top: 41%;
    position: absolute;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-profile-email {
    width: 60%;
    left: 20%;
    top: 48%;
    position: absolute;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-profile-club {
    width: 62%;
    left: 20%;
    top: 55%;
    position: absolute;
    border-radius: 30px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-profile-services {
    width: 60%;
    height: 20%;
    left: 20%;
    top: 62%;
    position: absolute;
    border-radius: 30px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-profile-btn-update {
    cursor: pointer;
    width: 20%;
    position: absolute;
    top: 86%;
    left: 62%;
    border-radius: 60px;
    padding: 10px;
    background-color: hsl(0, 80%, 65%);
    border: 8px solid hsl(0, 95%, 75%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.update-profile-btn-update:hover {
    background-color: hsl(0, 80%, 75%);
    border: 8px solid hsl(0, 80%, 90%);
}.update-profile-btn-close {
    cursor: pointer;
    width: 20%;
    position: absolute;
    top: 86%;
    left: 20%;
    border-radius: 60px;
    padding: 10px;
    background-color: hsl(0, 80%, 65%);
    border: 8px solid hsl(0, 95%, 75%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.update-profile-btn-close:hover {
    background-color: hsl(0, 80%, 75%);
    border: 8px solid hsl(0, 80%, 90%);
}
.view-profile {
    width: 40%;
    height: 70%;
    position: absolute;
    top: 20%;
    left: 29.4%;
    opacity: 0.9;
    border-radius: 60px;
    border: 8px solid hsl(0, 90%, 75%);
    background-image: -webkit-gradient(linear, right top, left top, from(hsl(0, 90%, 75%)), to(hsl(0, 90%, 85%)));
    background-image: -webkit-linear-gradient(right, hsl(0, 90%, 75%), hsl(0, 90%, 85%));
    background-image: linear-gradient(to left, hsl(0, 90%, 75%), hsl(0, 90%, 85%));
}
.view-profile-header {
    text-align: center;
    color: hsl(0, 100%, 100%);
}
.update-profile-title {
    width: 62%;
    left: 20%;
    top: 20%;
    position: absolute;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-profile-fn {
    width: 60%;
    left: 20%;
    top: 27%;
    position: absolute;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-profile-ln {
    width: 60%;
    left: 20%;
    top: 34%;
    position: absolute;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-profile-mobile {
    width: 60%;
    left: 20%;
    top: 41%;
    position: absolute;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-profile-email {
    width: 60%;
    left: 20%;
    top: 48%;
    position: absolute;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-profile-services {
    width: 60%;
    height: 20%;
    left: 20%;
    top: 55%;
    position: absolute;
    border-radius: 30px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.view-profile-btn-close {
    cursor: pointer;
    width: 20%;
    position: absolute;
    top: 85%;
    left: 40%;
    border-radius: 60px;
    padding: 10px;
    background-color: hsl(0, 80%, 65%);
    border: 8px solid hsl(0, 95%, 75%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.view-profile-btn-close:hover {
    background-color: hsl(0, 80%, 75%);
    border: 8px solid hsl(0, 80%, 90%);
}
.report-options {
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: -webkit-gradient(linear, left top, right top, from(hsl(0, 90%, 75%)), to(hsl(0, 80%, 85%)));
    background-image: -webkit-linear-gradient(left, hsl(0, 90%, 75%), hsl(0, 80%, 85%));
    background-image: linear-gradient(to right, hsl(0, 90%, 75%), hsl(0, 80%, 85%));
}
.report-option-btn-0 {
    cursor: pointer;
    position: absolute;
    width: 10%;
    height: 10%;
    top: 80%;
    left: 45%;
    background-color: hsl(0, 90%, 75%);
    border-radius: 60px;
    border: 8px solid hsl(0, 100%, 100%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.report-option-btn-0:hover {
    background-color: hsl(0, 80%, 85%);
    border: 8px solid hsl(0, 80%, 70%);
}
.finance-options {
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: -webkit-gradient(linear, left top, right top, from(hsl(0, 80%, 45%)), to(hsl(0, 75%, 90%)));
    background-image: -webkit-linear-gradient(left, hsl(0, 80%, 45%), hsl(0, 75%, 90%));
    background-image: linear-gradient(to right, hsl(0, 80%, 45%), hsl(0, 75%, 90%));
}
.finance-option-btn-0 {
    cursor: pointer;
    width: 8%;
    height: 15%;
    position: absolute;
    top: 40%;
    left: 40%;
    border-radius: 60px;
    background-color: hsl(0, 80%, 65%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
    border: 8px solid hsl(0, 80%, 90%);
}
.finance-option-btn-0:hover {
    background-color: hsl(0, 80%, 85%);
    border: 8px solid hsl(0, 80%, 70%);
}
.finance-option-btn-1 {
    cursor: pointer;
    min-width: 8%;
    max-width: 8%;
    height: 15%;
    position: absolute;
    top: 40%;
    left: 50%;
    border-radius: 60px;
    background-color: hsl(0, 80%, 75%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
    border: 8px solid hsl(0, 80%, 65%);
}
.finance-option-btn-1:hover {
    background-color: hsl(0, 80%, 85%);
    border: 8px solid hsl(0, 80%, 70%);
}
.finance-option-btn-2 {
    cursor: pointer;
    position: absolute;
    width: 10%;
    height: 10%;
    top: 80%;
    left: 45%;
    background-color: hsl(0, 90%, 75%);
    border-radius: 60px;
    border: 8px solid hsl(0, 100%, 100%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.finance-option-btn-2:hover {
    background-color: hsl(0, 80%, 85%);
    border: 8px solid hsl(0, 80%, 70%);
}
.view-finances {
    width: 40%;
    height: 70%;
    position: absolute;
    top: 20%;
    left: 29.4%;
    opacity: 0.9;
    border-radius: 60px;
    border: 8px solid hsl(0, 90%, 75%);
    background-image: -webkit-gradient(linear, right top, left top, from(hsl(0, 90%, 75%)), to(hsl(0, 90%, 85%)));
    background-image: -webkit-linear-gradient(right, hsl(0, 90%, 75%), hsl(0, 90%, 85%));
    background-image: linear-gradient(to left, hsl(0, 90%, 75%), hsl(0, 90%, 85%));
}
.view-finances-header {
    text-align: center;
    color: hsl(0, 100%, 100%);
}
.view-finances-btn-close {
    cursor: pointer;
    width: 20%;
    position: absolute;
    top: 86%;
    left: 40%;
    border-radius: 60px;
    padding: 10px;
    background-color: hsl(0, 80%, 65%);
    border: 8px solid hsl(0, 95%, 95%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.view-finances-btn-close:hover {
    background-color: hsl(0, 80%, 85%);
    border: 8px solid hsl(0, 80%, 70%);
}
.view-finances-view {
    width: 60%;
    height: 60%;
    position: absolute;
    top: 20%;
    left: 20%;
    border-radius: 20px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.hello-btn {
    border-radius: 5px;
    border: 0px;
    margin: 10px 5px 5px 5px;
    padding:5px;
    position: relative;
    align: center;
}
.header {
    width: 100%;
    height: 40%;
    color: hsl(0, 100%, 100%);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    position: absolute;
    background-image: -webkit-gradient(linear, left top, right top, from(hsl(0, 80%, 60%)),to(hsl(0, 90%, 80%)));
    background-image: -webkit-linear-gradient(left, hsl(0, 80%, 60%),hsl(0, 90%, 80%));
    background-image: linear-gradient(to right, hsl(0, 80%, 60%),hsl(0, 90%, 80%));
}
.login {
    width: 30%;
    height: 25%;
    border-radius: 15px;
    text-align: center;
    color: hsl(0, 100%, 100%);
    font-weight: bold;
    left: 30%;
    top: 25%;
    margin-top: 5px;
    padding: 2.5%;
    padding-top: 0.5%;
    position: absolute;
    background-image: -webkit-gradient(linear, left top, right top, from(hsl(0, 90%, 80%)), to(hsl(0, 80%, 60%)));
    background-image: -webkit-linear-gradient(left, hsl(0, 90%, 80%), hsl(0, 80%, 60%));
    background-image: linear-gradient(to right, hsl(0, 90%, 80%), hsl(0, 80%, 60%));
    border: 10px solid hsl(0, 80%, 60%);
}
.login-txt-uname {
   border-radius: 5px;
   width: 60%;
   padding: 5px;
   border: 5px solid hsl(0, 80%, 60%);
   margin-top: 1%;
   outline:none;

}
.login-txt-pword {
    border-radius: 5px;
    border: 0px;
    margin-top: 1%;
    width: 60%;
    padding: 5px;
    border: 5px solid hsl(0, 80%, 60%);
    outline:none;
}
.login-btn-submit {
    cursor: pointer;
    border: 5px solid hsl(0, 90%, 95%);
    border-radius: 60px;
    padding: 2% 8% 2% 8%;
    margin-top: 2.5%;
    color: hsl(0, 100%, 100%);
    font-weight: bold;
    background-color: hsl(0, 90%, 70%);
    outline:none;
}
.login-btn-submit:hover {
    border: 5px solid hsl(0, 80%, 60%);
    background-color: hsl(0, 90%, 85%);

}
.heading {
    position: relative;
    padding: 2%;
}
.header {}
.footer {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    top: 40%;
    background-color: hsl(0, 100%, 90%);
}
.facebook_link {
    width: 4%;
    height: 7%;
    border-radius: 30px;
    margin-top: 20%;
    margin-right: 3%;
    border: 1px solid hsl(0, 90%, 80%);
    display: none;
}
.twitter_link {
    width: 4%;
    height: 7%;
    border-radius: 30px;
    margin-top: 20%;
    margin-right: 3%;
    border: 4px solid hsl(0, 90%, 80%);
    display: none;
}
.instagram_link {
    width: 4%;
    height: 7%;
    border-radius: 30px;
    margin-top: 20%;
    margin-right: 3%;
    border: 4px solid hsl(0, 90%, 80%);
    display: none;
}
.notification {
    width: 30%;
    background-color: hsl(0, 90%, 80%);
    color: hsl(0, 100%, 100%);
    border: 4px solid hsl(0, 70%, 70%);
    padding-top: 1%;
    padding-bottom: 1%;
    border-radius: 10px;
    font-weight:bold;
    position: absolute;
    text-align: center;
    opacity: 0.99;
    left: 33.1%;
    top: 35.3%;
}
.notify-head {
    width: 20%;
    top: 32.5%;
    left: 38.5%;
    color: hsl(0, 100%, 100%);
    font-weight: bold;
    background: hsl(0, 90%, 80%);
    border: 4px solid hsl(0, 70%, 70%);
    position: absolute;
    border-radius: 10px;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    text-align: center;
}
