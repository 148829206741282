.appointment-options {
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: linear-gradient(to right, hsl(0, 80%, 60%), hsl(0, 100%, 100%));
}
.appointment-option-btn-0 {
    cursor: pointer;
    width: 8%;
    height: 15%;
    position: absolute;
    top: 40%;
    left: 45%;
    border-radius: 60px;
    background-color: hsl(0, 80%, 70%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
    border: 8px solid hsl(0, 80%, 80%);
}
.appointment-option-btn-0:hover {
    background-color: hsl(0, 80%, 50%);
    border: 8px solid hsl(0, 80%, 85%);
}
.appointment-option-btn-1:hover { /* Add */
    background-color: hsl(0, 80%, 50%);
    border: 8px solid hsl(0, 80%, 65%);
}
.appointment-option-btn-2:hover { /* Cancel */
    background-color: hsl(0, 80%, 50%);
    border: 8px solid hsl(0, 80%, 90%);
}
.appointment-option-btn-3:hover { /* Home */
    background-color: hsl(0, 80%, 75%);
    border: 8px solid hsl(0, 80%, 90%);
}
.appointment-option-btn-1 {
    cursor: pointer;
    width: 8%;
    height: 15%;
    position: absolute;
    top: 40%;
    left: 35%;
    border-radius: 60px;
    background-color: hsl(0, 80%, 65%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
    border: 8px solid hsl(0, 80%, 90%);
}
.appointment-option-btn-2 {
    cursor: pointer;
    width: 8%;
    height: 15%;
    position: absolute;
    top: 40%;
    left: 55%;
    border-radius: 60px;
    background-color: hsl(0, 80%, 85%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
    border: 8px solid hsl(0, 80%, 70%);
}
.appointment-option-btn-3 {
    cursor: pointer;
    position: absolute;
    width: 10%;
    height: 10%;
    top: 80%;
    left: 45%;
    background-color: hsl(0, 90%, 75%);
    border-radius: 60px;
    border: 8px solid hsl(0, 100%, 100%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
