.add-appointment {
    width: 40%;
    height: 70%;
    position: absolute;
    top: 20%;
    left: 29.4%;
    opacity: 0.8;
    border-radius: 60px;
    border: 8px solid hsl(0, 90%, 75%);
    background-image: linear-gradient(to left, hsl(0, 90%, 80%), hsl(0, 90%, 85%));
}
.add-appointment-header {
    text-align: center;
    color: hsl(0, 100%, 100%);
}
.add-appointment-client-id {
    width: 60%;
    left: 20%;
    top: 20%;
    position: absolute;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-appointment-type {
    width: 62%;
    left: 20%;
    top: 30%;
    position: absolute;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-appointment-day {
    width: 62%;
    left: 20%;
    top: 40%;
    position: absolute;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-appointment-time {
    width: 62%;
    left: 20%;
    top: 50%;
    position: absolute;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-appointment-btn-add {
    cursor: pointer;
    width: 20%;
    position: absolute;
    top: 70%;
    left: 60%;
    border-radius: 60px;
    padding: 10px;
    background-color: hsl(0, 80%, 65%);
    border: 8px solid hsl(0, 95%, 75%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;

}.add-appointment-btn-close {
    cursor: pointer;
    width: 20%;
    position: absolute;
    top: 70%;
    left: 20%;
    border-radius: 60px;
    padding: 10px;
    background-color: hsl(0, 80%, 65%);
    border: 8px solid hsl(0, 95%, 75%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.add-appointment-btn-add:hover {
    background-color: hsl(0, 80%, 75%);
    border: 8px solid hsl(0, 80%, 90%);
}

.add-appointment-btn-close:hover {
    background-color: hsl(0, 80%, 75%);
    border: 8px solid hsl(0, 80%, 90%);
}