.report-options {
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: linear-gradient(to right, hsl(0, 90%, 75%), hsl(0, 80%, 85%));
}
.report-option-btn-0 {
    cursor: pointer;
    position: absolute;
    width: 10%;
    height: 10%;
    top: 80%;
    left: 45%;
    background-color: hsl(0, 90%, 75%);
    border-radius: 60px;
    border: 8px solid hsl(0, 100%, 100%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.report-option-btn-0:hover {
    background-color: hsl(0, 80%, 85%);
    border: 8px solid hsl(0, 80%, 70%);
}