.update-client {
    width: 40%;
    height: 70%;
    position: absolute;
    top: 19.3%;
    left: 29.4%;
    border-radius: 60px;
    border: 8px solid hsl(0, 90%, 75%);
    background-image: linear-gradient(to left, hsl(0, 90%, 80%), hsl(0, 90%, 85%));
    opacity: 0.9;
}
.update-client-title {
    width: 90%;
    position: absolute;
    top: 27.5%;
    left: 4%;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-client-fn {
    width: 88%;
    position: absolute;
    top: 34%;
    left: 4%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-client-ln {
    width: 88%;
    position: absolute;
    top: 40.5%;
    left: 4%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-client-mn {
    width: 88%;
    position: absolute;
    top: 47%;
    left: 4%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-client-ea {
    width: 88%;
    position: absolute;
    top: 53.5%;
    left: 4%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-client-address {
    width: 88%;
    height: 10%;
    position: absolute;
    top: 60%;
    left: 4%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    padding-top: 2%;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-client-btn-cancel {
    cursor: pointer;
    width: 20%;
    position: absolute;
    top: 78%;
    left: 12%;
    border-radius: 60px;
    padding: 10px;
    background-color: hsl(0, 80%, 65%);
    border: 8px solid hsl(0, 100%, 100%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.update-client-btn-update {
    cursor: pointer;
    width: 20%;
    position: absolute;
    top: 78%;
    left: 66%;
    border-radius: 60px;
    padding: 10px;
    background-color: hsl(0, 80%, 65%);
    border: 8px solid hsl(0, 95%, 75%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.update-client-btn-cancel:hover {
    background-color: hsl(0, 90%, 70%);
    border: 8px solid hsl(0, 100%, 90%);
}
.update-client-btn-update:hover {
    background-color: hsl(0, 90%, 75%);
    border: 8px solid hsl(0, 100%, 70%);
}
.update-client-header {
    text-align: center;
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}

.update-client-search {
    width: 60%;
    position: absolute;
    top: 21%;
    left: 4%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-client-btn-search {
    cursor: pointer;
    width: 22%;
    position: absolute;
    top: 20.5%;
    left: 71%;
    border-radius: 60px;
    padding: 5px;
    border-radius: 60px;
    background-color: hsl(0, 80%, 65%);
    border: 4px solid hsl(0, 95%, 75%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.update-client-btn-search:hover {
    background-color: hsl(0, 90%, 75%);
    border: 4px solid hsl(0, 100%, 70%);
}