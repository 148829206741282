.view-profile {
    width: 40%;
    height: 70%;
    position: absolute;
    top: 20%;
    left: 29.4%;
    opacity: 0.9;
    border-radius: 60px;
    border: 8px solid hsl(0, 90%, 75%);
    background-image: linear-gradient(to left, hsl(0, 90%, 75%), hsl(0, 90%, 85%));
}
.view-profile-header {
    text-align: center;
    color: hsl(0, 100%, 100%);
}
.update-profile-title {
    width: 62%;
    left: 20%;
    top: 20%;
    position: absolute;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-profile-fn {
    width: 60%;
    left: 20%;
    top: 27%;
    position: absolute;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-profile-ln {
    width: 60%;
    left: 20%;
    top: 34%;
    position: absolute;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-profile-mobile {
    width: 60%;
    left: 20%;
    top: 41%;
    position: absolute;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-profile-email {
    width: 60%;
    left: 20%;
    top: 48%;
    position: absolute;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.update-profile-services {
    width: 60%;
    height: 20%;
    left: 20%;
    top: 55%;
    position: absolute;
    border-radius: 30px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.view-profile-btn-close {
    cursor: pointer;
    width: 20%;
    position: absolute;
    top: 85%;
    left: 40%;
    border-radius: 60px;
    padding: 10px;
    background-color: hsl(0, 80%, 65%);
    border: 8px solid hsl(0, 95%, 75%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.view-profile-btn-close:hover {
    background-color: hsl(0, 80%, 75%);
    border: 8px solid hsl(0, 80%, 90%);
}