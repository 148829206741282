.finance-options {
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: linear-gradient(to right, hsl(0, 80%, 45%), hsl(0, 75%, 90%));
}
.finance-option-btn-0 {
    cursor: pointer;
    width: 8%;
    height: 15%;
    position: absolute;
    top: 40%;
    left: 40%;
    border-radius: 60px;
    background-color: hsl(0, 80%, 65%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
    border: 8px solid hsl(0, 80%, 90%);
}
.finance-option-btn-0:hover {
    background-color: hsl(0, 80%, 85%);
    border: 8px solid hsl(0, 80%, 70%);
}
.finance-option-btn-1 {
    cursor: pointer;
    min-width: 8%;
    max-width: 8%;
    height: 15%;
    position: absolute;
    top: 40%;
    left: 50%;
    border-radius: 60px;
    background-color: hsl(0, 80%, 75%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
    border: 8px solid hsl(0, 80%, 65%);
}
.finance-option-btn-1:hover {
    background-color: hsl(0, 80%, 85%);
    border: 8px solid hsl(0, 80%, 70%);
}
.finance-option-btn-2 {
    cursor: pointer;
    position: absolute;
    width: 10%;
    height: 10%;
    top: 80%;
    left: 45%;
    background-color: hsl(0, 90%, 75%);
    border-radius: 60px;
    border: 8px solid hsl(0, 100%, 100%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.finance-option-btn-2:hover {
    background-color: hsl(0, 80%, 85%);
    border: 8px solid hsl(0, 80%, 70%);
}