.list-client {
    width: 40%;
    height: 70%;
    top: 19.3%;
    left: 29.4%;
    position: absolute;
    border-radius: 60px;
    border: 8px solid hsl(0, 90%, 75%);
    background-image: linear-gradient(to left, hsl(0, 90%, 80%), hsl(0, 90%, 85%));
}
.list-client-header {
    text-align: center;
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.list-client-btn-cancel {
    cursor: pointer;
    width: 20%;
    position: absolute;
    top: 85%;
    left: 43%;
    border-radius: 60px;
    padding: 10px;
    background-color: hsl(0, 80%, 65%);
    border: 8px solid hsl(0, 100%, 100%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.list-client-btn-cancel:hover {
    background-color: hsl(0, 90%, 70%);
    border: 8px solid hsl(0, 100%, 90%);
}
.list-client-information {
    width: 70%;
    min-height: 60%;
    max-height: 60%;
    position: absolute;
    top: 16%;
    left: 15%;
    overflow-y: auto;
    border-radius: 10px;
    padding: 10px;
    background-color: hsl(0, 80%, 70%);
}

.client-information {
    height: 50px;
    width: auto;
    cursor: pointer;
    list-style-type: none;
    padding: 5%;
    border-radius: 10px;
    font-weight: bold;
    background-color: hsl(0, 90%, 75%);
}
.client-information:hover {
    background-color: hsl(0, 90%, 70%);
    border: 1px solid hsl(0, 80%, 90%);
}
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: hsl(0, 95%, 70%);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}