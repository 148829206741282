.home-page {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, hsl(0, 80%, 60%),hsl(0, 90%, 80%));
    position: absolute;
}
.home-header {
    width: 100%;
    height: 10%;
    background-image: linear-gradient(to right, hsl(0, 90%, 80%), hsl(0, 80%, 60%));
    position: absolute;
    top: 0%;
    border-bottom: 5px solid hsl(0, 80%, 60%);
    text-align: center;
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.home-footer {
    width: 100%;
    height: 10%;
    background-image: linear-gradient(to right, hsl(0, 90%, 80%), hsl(0, 80%, 60%));
    position: absolute;
    top: 90%;
    border-top: 5px solid hsl(0, 80%, 60%);
}
.option-btn-0 {
    cursor: pointer;
    position: absolute;
    width: 10%;
    height: 15%;
    top: 40%;
    left: 20%;
    background-color: hsl(0, 80%, 45%);
    border-radius: 10px;
    border: 8px solid hsl(0, 80%, 85%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;

}
.option-btn-0:hover {
    background-color: hsl(0, 80%, 60%);
    border: 8px solid hsl(0, 80%, 70%);
}
.option-btn-1 {
    cursor: pointer;
    position: absolute;
    width: 10%;
    height: 15%;
    top: 40%;
    left: 31%;
    background-color: hsl(0, 80%, 50%);
    border-radius: 10px;
    border: 8px solid hsl(0, 80%, 80%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;

}
.option-btn-1:hover {
    background-color: hsl(0, 80%, 65%);
    border: 8px solid hsl(0, 80%, 60%);
}
.option-btn-2 {
    cursor: pointer;
    position: absolute;
    width: 10%;
    height: 15%;
    top: 40%;
    left: 42%;
    background-color: hsl(0, 80%, 55%);
    border-radius: 10px;
    border: 8px solid hsl(0, 80%, 75%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;

}
.option-btn-2:hover {
    background-color: hsl(0, 80%, 70%);
    border: 8px solid hsl(0, 80%, 60%);
}
.option-btn-3 {
    cursor: pointer;
    position: absolute;
    width: 10%;
    height: 15%;
    top: 40%;
    left: 53%;
    background-color: hsl(0, 80%, 60%);
    border-radius: 10px;
    border: 8px solid hsl(0, 95%, 65%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;

}
.option-btn-3:hover {
    background-color: hsl(0, 80%, 50%);
    border: 8px solid hsl(0, 80%, 80%);
}
.option-btn-4 {
    cursor: pointer;
    position: absolute;
    width: 10%;
    height: 15%;
    top: 40%;
    left: 64%;
    background-color: hsl(0, 80%, 65%);
    border-radius: 10px;
    border: 8px solid hsl(0, 95%, 60%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;

}
.option-btn-4:hover {
    background-color: hsl(0, 80%, 45%);
    border: 8px solid hsl(0, 95%, 90%);
}