.filter-client {
    width: 40%;
    height: 70%;
    top: 19.3%;
    left: 29.4%;
    position: absolute;
    border-radius: 60px;
    border: 8px solid hsl(0, 90%, 75%);
    background-image: linear-gradient(to left, hsl(0, 90%, 80%), hsl(0, 90%, 85%));
}
.filter-client-filter {
    width: 80%;
    height: 6%;
    position: absolute;
    top: 15%;
    left: 8%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 8px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
    outline: none
}
.filter-client-btn-cancel {
    cursor: pointer;
    width: 20%;
    position: absolute;
    top: 85%;
    left: 41%;
    border-radius: 60px;
    padding: 10px;
    background-color: hsl(0, 80%, 65%);
    border: 8px solid hsl(0, 100%, 100%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.filter-client-btn-cancel:hover {
    background-color: hsl(0, 90%, 70%);
    border: 8px solid hsl(0, 100%, 90%);
}
.filter-client-information {
    width: 80%;
    height: 50%;
    position: absolute;
    top: 28%;
    left: 8.5%;
    border-radius: 5px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 3px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}