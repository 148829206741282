.add-client {
    width: 40%;
    height: 70%;
    position: absolute;
    top: 19.3%;
    left: 29.4%;
    border-radius: 60px;
    border: 8px solid hsl(0, 90%, 75%);
    background-image: linear-gradient(to left, hsl(0, 90%, 80%), hsl(0, 90%, 85%));
    opacity: 0.9;
}
.add-client-title {
    width: 90%;
    position: absolute;
    top: 15%;
    left: 5%;
    border-radius: 60px;
    text-align: center;
    padding: 5px;
    border: 0px;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-client-fn {
    width: 88%;
    position: absolute;
    top: 21%;
    left: 5%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-client-ln {
    width: 88%;
    position: absolute;
    top: 26.5%;
    left: 5%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}

.add-client-gender {
    width: 90%;
    position: absolute;
    top: 32%;
    left: 5%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-client-race {
    width: 90%;
    position: absolute;
    top: 38%;
    left: 5%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-client-mn {
    width: 88%;
    position: absolute;
    top: 44%;
    left: 5%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-client-ea {
    width: 88%;
    position: absolute;
    top: 50%;
    left: 5%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-client-account-type {
    width: 90%;
    position: absolute;
    top: 56%;
    left: 5%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-client-member-count {
    width: 88%;
    position: absolute;
    top: 62%;
    left: 5%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-client-session-type {
    width: 90%;
    position: absolute;
    top: 68%;
    left: 5%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-client-account-paydate {
    width: 88%;
    position: absolute;
    top: 74%;
    left: 5%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-client-address {
    width: 88%;
    height: 10%;
    position: absolute;
    top: 76%;
    left: 5%;
    border-radius: 60px;
    padding: 5px;
    border: 0px;
    padding-top: 2%;
    text-align: center;
    border: 2px solid hsl(0, 95%, 75%);
    background-color: hsl(0, 95%, 90%);
    font-weight: bold;
}
.add-client-btn-cancel {
    cursor: pointer;
    width: 20%;
    position: absolute;
    top: 82%;
    left: 14%;
    border-radius: 60px;
    padding: 10px;
    background-color: hsl(0, 80%, 65%);
    border: 8px solid hsl(0, 100%, 100%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.add-client-btn-cancel:hover {
    background-color: hsl(0, 90%, 70%);
    border: 8px solid hsl(0, 100%, 90%);
}
.add-client-btn-add {
    cursor: pointer;
    width: 20%;
    position: absolute;
    top: 82%;
    left: 66%;
    border-radius: 60px;
    padding: 10px;
    background-color: hsl(0, 80%, 65%);
    border: 8px solid hsl(0, 95%, 75%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}
.add-client-btn-add:hover {
    background-color: hsl(0, 90%, 75%);
    border: 8px solid hsl(0, 100%, 70%);
}
.add-client-header {
    text-align: center;
    color: hsl(0, 100%, 100%);
    font-weight: bold;
}