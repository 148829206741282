.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.hello-btn {
    border-radius: 5px;
    border: 0px;
    margin: 10px 5px 5px 5px;
    padding:5px;
    position: relative;
    align: center;
}
.header {
    width: 100%;
    height: 40%;
    color: hsl(0, 100%, 100%);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    position: absolute;
    background-image: linear-gradient(to right, hsl(0, 80%, 60%),hsl(0, 90%, 80%));
}
.login {
    width: 30%;
    height: 25%;
    border-radius: 15px;
    text-align: center;
    color: hsl(0, 100%, 100%);
    font-weight: bold;
    left: 30%;
    top: 25%;
    margin-top: 5px;
    padding: 2.5%;
    padding-top: 0.5%;
    position: absolute;
    background-image: linear-gradient(to right, hsl(0, 90%, 80%), hsl(0, 80%, 60%));
    border: 10px solid hsl(0, 80%, 60%);
}
.login-txt-uname {
   border-radius: 5px;
   width: 60%;
   padding: 5px;
   border: 5px solid hsl(0, 80%, 60%);
   margin-top: 1%;
   outline:none;

}
.login-txt-pword {
    border-radius: 5px;
    border: 0px;
    margin-top: 1%;
    width: 60%;
    padding: 5px;
    border: 5px solid hsl(0, 80%, 60%);
    outline:none;
}
.login-btn-submit {
    cursor: pointer;
    border: 5px solid hsl(0, 90%, 95%);
    border-radius: 60px;
    padding: 2% 8% 2% 8%;
    margin-top: 2.5%;
    color: hsl(0, 100%, 100%);
    font-weight: bold;
    background-color: hsl(0, 90%, 70%);
    outline:none;
}
.login-btn-submit:hover {
    border: 5px solid hsl(0, 80%, 60%);
    background-color: hsl(0, 90%, 85%);

}
.heading {
    position: relative;
    padding: 2%;
}
.header {}
.footer {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    top: 40%;
    background-color: hsl(0, 100%, 90%);
}
.facebook_link {
    width: 4%;
    height: 7%;
    border-radius: 30px;
    margin-top: 20%;
    margin-right: 3%;
    border: 1px solid hsl(0, 90%, 80%);
    display: none;
}
.twitter_link {
    width: 4%;
    height: 7%;
    border-radius: 30px;
    margin-top: 20%;
    margin-right: 3%;
    border: 4px solid hsl(0, 90%, 80%);
    display: none;
}
.instagram_link {
    width: 4%;
    height: 7%;
    border-radius: 30px;
    margin-top: 20%;
    margin-right: 3%;
    border: 4px solid hsl(0, 90%, 80%);
    display: none;
}
.notification {
    width: 30%;
    background-color: hsl(0, 90%, 80%);
    color: hsl(0, 100%, 100%);
    border: 4px solid hsl(0, 70%, 70%);
    padding-top: 1%;
    padding-bottom: 1%;
    border-radius: 10px;
    font-weight:bold;
    position: absolute;
    text-align: center;
    opacity: 0.99;
    left: 33.1%;
    top: 35.3%;
}
.notify-head {
    width: 20%;
    top: 32.5%;
    left: 38.5%;
    color: hsl(0, 100%, 100%);
    font-weight: bold;
    background: hsl(0, 90%, 80%);
    border: 4px solid hsl(0, 70%, 70%);
    position: absolute;
    border-radius: 10px;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    text-align: center;
}