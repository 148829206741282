.client-options {
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: linear-gradient(to right, hsl(0, 80%, 70%), hsl(0, 90%, 90%));
}
.client-option-btn-0 {
    cursor: pointer;
    position: absolute;
    width: 8%;
    height: 15%;
    top: 40%;
    left: 31%;
    background-color: hsl(0, 70%, 65%);
    border-radius: 60px;
    border: 8px solid hsl(0, 90%, 85%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;

}
.client-option-btn-0:hover {
    background-color: hsl(0, 70%, 65%);
    border: 8px solid hsl(0, 90%, 95%);
}
.client-option-btn-1 {
    cursor: pointer;
    position: absolute;
    width: 8%;
    height: 15%;
    top: 20%;
    left: 46%;
    background-color: hsl(0, 90%, 75%);
    border-radius: 60px;
    border: 8px solid hsl(0, 90%, 70%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;

}
.client-option-btn-1:hover {
    background-color: hsl(0, 90%, 65%);
    border: 8px solid hsl(0, 80%, 90%);
}
.client-option-btn-2 {
    cursor: pointer;
    position: absolute;
    width: 8%;
    height: 15%;
    top: 40%;
    left: 61%;
    background-color: hsl(0, 90%, 80%);
    border-radius: 60px;
    border: 8px solid hsl(0, 80%, 65%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;

}
.client-option-btn-2:hover {
    background-color: hsl(0, 90%, 65%);
    border: 8px solid hsl(0, 80%, 85%);
}
.client-option-btn-3 {
    cursor: pointer;
    position: absolute;
    width: 8%;
    height: 15%;
    top: 60%;
    left: 46%;
    background-color: hsl(0, 90%, 75%);
    border-radius: 60px;
    border: 8px solid hsl(0, 90%, 70%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;

}
.client-option-btn-3:hover {
    background-color: hsl(0, 90%, 75%);
    border: 8px solid hsl(0, 90%, 65%);
}
.client-option-btn-4 {
    cursor: pointer;
    position: absolute;
    width: 10%;
    height: 10%;
    top: 80%;
    left: 45%;
    background-color: hsl(0, 90%, 75%);
    border-radius: 60px;
    border: 8px solid hsl(0, 100%, 100%);
    color: hsl(0, 100%, 100%);
    font-weight: bold;

}
.client-option-btn-4:hover {
    background-color: hsl(0, 90%, 70%);
    border: 8px solid hsl(0, 100%, 85%);
}
.client-options-header {
    width: 100%;
    height: 8%;
    background-image: linear-gradient(to right, hsl(0, 90%, 90%), hsl(0, 80%, 70%));
    position: absolute;
    top:0%;
    border-bottom: 8px solid hsl(0, 80%, 70%);
    text-align: center;
    color: hsl(0, 100%, 100%);
    font-weight: bold;
    padding-top: 1%;
    padding-bottom: 1%;
}